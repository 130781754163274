import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Box, Button, Stack } from "@mui/material";

export const ResponsiveAppBar = () => {
  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Stack direction="row" display="flex" flex="1" justifyContent="space-between" alignItems="center">
            <Box display="flex" flex="1" justifyContent="flex-start" alignItems="center">
              <img src="/icon-192.png" width={48} alt="Pitch2Table logo" />
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  pl: 2,
                  fontWeight: 700,
                  textDecoration: "none",
                  color: "white",
                }}
              >
                Pitch2Table
              </Typography>
            </Box>
            <Button href="https://app.pitch2table.com" variant="outlined" color="info">
              LOGIN
            </Button>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
