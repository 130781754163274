import * as React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { MaxWidthBox } from "./MaxWidthBox";
import { useLinks, useWebsite } from "./queries";
import { useTheme } from "@mui/material";

/**
 * @param {object} props
 * @param {string} props.name
 * @param {string} props.slug
 */
const FooterLink = ({ name, slug }) => {
  const theme = useTheme();

  return (
    <Link key={slug} component={RouterLink} to={`/${slug}`}>
      <Typography color={theme.palette.secondary.main}>{name}</Typography>
    </Link>
  );
};

const ContentLinks = () => {
  const designers = useLinks("Designer Articles");
  const selfPublishers = useLinks("Publishing Articles");
  const publishers = useLinks("Publisher Articles");

  return (
    <Box flex={1} flexDirection="row" display="flex" justifyContent="center">
      <Box flex={1}>
        <Typography color="white" variant="h6">
          Designers
        </Typography>
        {designers.map((link) => (
          <FooterLink key={link.slug} name={link.name} slug={link.slug} />
        ))}
      </Box>
      <Box flex={1}>
        <Typography color="white" variant="h6">
          Self Publishers
        </Typography>
        {selfPublishers.map((link) => (
          <FooterLink key={link.slug} name={link.name} slug={link.slug} />
        ))}
      </Box>
      <Box flex={1}>
        <Typography color="white" variant="h6">
          Publishers
        </Typography>
        {publishers.map((link) => (
          <FooterLink key={link.slug} name={link.name} slug={link.slug} />
        ))}
      </Box>
    </Box>
  );
};

export const Footer = () => {
  const links = useLinks("Legal Links");
  const { data: web } = useWebsite("Pitch2Table");

  return (
    <Box pt={2} pb={0} bgcolor="black" display="flex" justifyContent="center">
      <MaxWidthBox>
        <Box flex={1} flexDirection="column" display="flex" justifyContent="center">
          {false && <ContentLinks />}
          <Box py={4}>
            <Divider color="white" />
          </Box>
          <Box flex={1} flexDirection="row" display="flex" justifyContent="space-between">
            {links.map((link) => (
              <FooterLink key={link.slug} name={link.name} slug={link.slug} />
            ))}
          </Box>
          <Box flex={1} py={4} flexDirection="row" display="flex">
            <Typography color="white" variant="body2">
              {web ? web.fields.copyright : ""}
              {web ? web.fields.legalNotice : ""}
            </Typography>
          </Box>
        </Box>
      </MaxWidthBox>
    </Box>
  );
};
