import * as React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { MaxWidthBox } from "./MaxWidthBox";
import hero1 from "../../assets/web-hero-1.png";

export const Hero = () => {
  const theme = useTheme();

  return (
    <Box pt={2} pb={0} display="flex" justifyContent="center" sx={{ backgroundColor: "white" }}>
      <MaxWidthBox>
        <Box flex={1} alignContent="center">
          <Typography variant="h1" p={2} textAlign="center" fontWeight="bold">
            Get games{" "}
            <Typography
              component="span"
              variant="inherit"
              sx={{ textDecoration: "underline" }}
              color={theme.palette.primary.main}
            >
              signed
            </Typography>
          </Typography>
          <Typography variant="h6" p={4} textAlign="center">
            Pitch Management for{" "}
            <Typography component="span" variant="inherit" color={theme.palette.primary.main}>
              designers
            </Typography>
            . Submission management for{" "}
            <Typography component="span" variant="inherit" color={theme.palette.primary.main}>
              publishers
            </Typography>
            .
          </Typography>
          <Typography variant="h6" p={2} textAlign="center">
            Keep on track of your pitches, know when to follow up and when to focus on what you do best.
          </Typography>
          <Box py={2} justifyContent="center" display="flex" flex={1}>
            <Button href="https://app.pitch2table.com" variant="contained">
              GET STARTED
            </Button>
          </Box>
          <img
            src={hero1}
            alt="sample images of app showing publisher activity"
            style={{ maxWidth: "100%", aspectRatio: "contain", borderRadius: 16 }}
          />
        </Box>
      </MaxWidthBox>
    </Box>
  );
};
