import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { Typography } from "@mui/material";

/**
 * @type {import("@contentful/rich-text-react-renderer").Options}
 */
export const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
      <p>
        <Typography>{children}</Typography>
      </p>
    ),
    [BLOCKS.QUOTE]: (_node, children) => <blockquote>{children}</blockquote>,
  },
  preserveWhitespace: true,
  renderText: (text) => text.replace("!", "?"),
};
