import * as React from "react";
import { ResponsiveAppBar } from "./ResponsiveAppBar";
import { Box, Typography } from "@mui/material";
import { Footer } from "./Footer";
import { MaxWidthBox } from "./MaxWidthBox";
import { useHomepageBySlug } from "./queries";
import { Hero } from "./Hero";
import { Features } from "./Features";

const Testimonials = () => (
  <Box pt={2} pb={0} display="flex" justifyContent="center">
    <MaxWidthBox>
      <Box flex={1} justifyContent="center">
        <Typography variant="h5">Don't take our word for it</Typography>
      </Box>
    </MaxWidthBox>
  </Box>
);

export const MarketingSite = () => {
  const { data: entry } = useHomepageBySlug("designer-home");

  return (
    <Box>
      <ResponsiveAppBar />
      <Hero />
      <Features features={(entry && entry.fields && entry.fields.features) || []} />
      {false && <Testimonials />}
      <Footer />
    </Box>
  );
};
