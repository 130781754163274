import React from "react";

export const AppFrame = ({ children }) => {
  return (
    <div
      style={{
        padding: 0,
        margin: 0,
        minHeight: "100vh",
        width: "100vw",
        maxWidth: "100%",
      }}
    >
      {children}
    </div>
  );
};
