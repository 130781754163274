import { createTheme } from "@mui/material";

const carissma = "#ec7f98";
const cannonPink = "#8b3d60";
const asparagus = "#7ca166";
// const seaBuckthorn = "#f9912e";
const pomegrante = "#f44336";
// const amethystSmoke = "#A19EB8";
// const newYorkPink = "#D9888C";
export const theme = createTheme({
  typography: {
    fontFamily: "proxima-nova, sans-serif",
    h1: {
      fontFamily: "sofia-pro, sans-serif",
      fontWeight: 800,
    },
    h2: {
      fontFamily: "sofia-pro, sans-serif",
      fontWeight: 800,
    },
    h3: {
      fontFamily: "sofia-pro, sans-serif",
      fontWeight: 800,
    },
    h4: {
      fontFamily: "sofia-pro, sans-serif",
      fontWeight: 800,
    },
    h5: {
      fontFamily: "sofia-pro, sans-serif",
      fontWeight: 800,
    },
    h6: {
      fontFamily: "sofia-pro, sans-serif",
      fontWeight: 800,
    },
    body2: {
      fontFamily: "proxima-nova, sans-serif",
    },
  },
  // palette: {
  //   primary: { main: carissma, light: amethystSmoke },
  //   info: { main: cannonPink },
  //   success: { main: asparagus },
  //   warning: { main: seaBuckthorn },
  //   error: { main: pomegrante },
  //   background: { default: "transparent" },
  //   secondary: { main: newYorkPink },
  // },
  palette: {
    mode: "light",
    background: { default: "#F6F5F5", paper: "#ffffff" },
    primary: {
      main: cannonPink,
      contrastText: "#ffffff",
    },
    secondary: {
      main: carissma,
    },
    info: {
      main: "#ffffff",
    },
    success: {
      main: asparagus,
    },
    error: {
      main: pomegrante,
    },
  },
});
