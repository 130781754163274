import * as React from "react";
import { Box, Card, CardContent, CardHeader } from "@mui/material";
import { MaxWidthBox } from "./MaxWidthBox";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { options } from "./contentful-helpers";

/**
 * @param {object} props
 * @param {string} props.name visible title of the content
 * @param {import("@contentful/rich-text-types").Document} props.longDescription rich text
 * @param {string} props.image
 */
const Feature = ({ name, longDescription, image }) => {
  return (
    <Box pt={1} pb={0} display="flex" justifyContent="center">
      <MaxWidthBox>
        <Card elevation={1}>
          <Box display="flex" sx={{ flexDirection: { xs: "column", sm: "row" } }} alignItems="center">
            <Box flex={1}>
              <CardHeader title={name} />
              <CardContent>{documentToReactComponents(longDescription, options)}</CardContent>
            </Box>
            <img src={image} width={300} height={400} alt="placeholder" />
          </Box>
        </Card>
      </MaxWidthBox>
    </Box>
  );
};

/**
 * @param {object} props
 * @param {object[]} props.features
 * @param {object} props.features[].fields
 * @param {string} props.features[].fields.internalName
 * @param {string} props.features[].fields.name visible title of the content
 * @param {string} props.features[].fields.featureImage
 * @param {import("@contentful/rich-text-types").Document} props.features[].fields.longDescription rich text
 */
export const Features = ({ features }) => {
  if (!features) {
    return null;
  }

  return features.map((feature) => (
    <Feature
      key={feature.fields.internalName}
      name={feature.fields.name}
      longDescription={feature.fields.longDescription}
      image={`http:${feature.fields.featureImage.fields.file.url}`}
    />
  ));
};
